#main-menu {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include media($desktop) {
    display: block;
  }

  .calc-wrap {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 20px; 
	padding: 16px;
	margin-top: -88px;

    @media(min-width: 520px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media($desktop) {
      @include outer-container(1280px);
      padding: 0px 16px;
      margin-top: -300px;
      margin-bottom: 40px;
      // flex-wrap: nowrap;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }

    >div {
      flex: 1 0 100%;
      margin-bottom: 16px;
      position: relative;

      @include media($desktop) {
		    // flex: 0 1 19%;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .hero {
    background: #fff url("../images/hero-bg2.jpg") no-repeat right 35%;

    height: 291px;
    padding-top: 33px;

    @include media($desktop) {
      height: 550px;
      display: block;
      padding-top: 60px;
    }
    
    @media (min-width: 2000px) {
      background-size: cover;
    }

    p {
      margin-top: 0;
    }
  }

  h1 {
    @extend %font-proximanova;
    color: #fff;
    margin: 0;
    text-align: center;

    font-weight: 300;
    text-shadow: 2px 2px 3px $grey;

    font-size: rem(35);

    @include media($desktop) {
      font-size: rem(46);
    }
    b {
      font-weight: 700;
    }
  }

  h1.calculator-title {
    @extend %font-proximanova;
    color: #000;
    font-weight: 700;
    font-size: calc(1.53125rem + 3.375vw);
    text-shadow: none;

    @include media($desktop) {
      font-size: 4.0625rem;
    }
  }

  h2 {
    @extend %font-proximanova;
    color: #fff;
    margin: 0;
    text-align: center;
    font-weight: 300;
    text-shadow: 1px 1px 3px $grey;

    font-size: rem(24);

    @include media($desktop) {
      font-size: rem(32);
    }

    b {
      font-weight: 700;
    }
  }
}

.menu-calculators {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  color: #fff;
  font-size: rem(24);
  font-weight: 700;
  text-shadow: 2px 2px 1px $grey;
  text-align: center;
  text-decoration: none;
  width: 100%;
  border: 3px solid #fff;
  position: relative;
  margin-bottom: 20px;

  height: 176px;

	display: flex;
  align-items: center;
	justify-content: center;
  
  @include media($desktop) {
    height: 360px;
  }

  &:hover {
    @include material-shadow(2);
    color: #fff;

    .overlay {
      background-color: transparent;
    }
  }

  &:active {
    @include material-shadow(3);
  }

  &.plant-density {
    background-image: url("../images/plant-density-bg.jpg");
  }

  &.seeding-rate {
    background-image: url("../images/seeding-rate-bg.jpg");
  }

  &.combine-optimization {
    background-image: url("../images/3a-l.png");
  }

  &.canola-counts {
	background-image: url("../images/canola-counts-mobile.jpg");
	background-position: bottom center;
	
	@include media($desktop) {
		background-image: url("../images/canola-counts-desktop.jpg");
	}
  }
  
  &.harvest-loss {
	background-image: url("../images/harvest-loss-mobile.jpg");
	
	@include media($desktop) {
		background-image: url("../images/harvest-loss-desktop.jpg");
	}
  }

  &.blackleg-loss {
	background-image: url("../images/blackleg-loss-desktop.jpg");
	background-position: center;
	
	@include media($desktop) {
		background-image: url("../images/blackleg-loss-desktop.jpg");
	}
  }

  &.sclerotinia-risk {
    background-image: url("../images/bg-Sclerotinia-infecting-crowns-in-Watrous-2012_Clint-Jurke_3-copy.jpg");
  }

  &.sclerotinia-economic {
    background-image: url("../images/bg-Sclerotinia-stem-lesion-late-Aug-2019_Clint-Jurke-2.jpg");
  }

  &.sclerotinia-resources {
    background-image: url("../images/bg-sclerotinia-pods.jpg");
  }

  .menu-button {
    padding: 5px 15px;
    background: #fadf06;
    color: #000;
    border-radius: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.125rem;
    text-shadow: none;
    text-decoration: none;
  }
  .menu-button:hover {
    background: #f7c500;
    text-decoration: underline;
  }


  .overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize(#000, 0.9);
    z-index: 0;

    &+span {
      display: block;
      position: relative;
    }
  }

  img {
    width: 45px;
    display: block;
    margin: 0 auto 20px;

    @include media($desktop) {
      width: 60px;
    }
  }

  &+p {
    display: block;
  }
}
