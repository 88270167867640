.two-columns {
  @media print {
    @include outer-container(100%);
    width: 100%;
  }

  &.show-result {
    .form {
      display: none;

      @include media($desktop) {
        display: block;
      }

      @media print {
        @include span-columns(6);
      }
    }

    .result {
      display: block;
    }
  }

  .accordion {
    p {
      font-size: rem(14);
	}
	
	&--harvest-loss p,
  &--blackleg-loss p {
		font-size: 1rem;
	}

    .toggle {
      @include media($desktop) {
        display: inline-block;
        cursor: auto;
      }

      &::after {
        @include media($desktop) {
          content: none;
        }
      }
    }

    .content {
      @include media($desktop) {
        display: block;
      }
    }
  }

  .form {
    @include media($desktop) {
      @include span-columns(6);
    }

    @media print {
      @include span-columns(6);
    }

    .pad {
      @media print {
        display: none;
      }
    }

    .tabs-intro {
      @media print {
        display: none;
      }
    }
  }

  .result {
    display: none;
    background-color: $light_green;
    #sticky-block {
      .numbers {
        .collapsed-box{
          display: none;
        }
        .h4 {
          .collapsed-box {
            display: none;
          }
        }
        .acre-cost {
          display: none;
        }

      }
    }
    @include media($desktop) {
      display: block;
      @include span-columns(6);
      background-color: $light_green;
      padding: 24px 0 0;
      position: sticky;
      top: 0;
    }

   /* &.sticky {
      @include media($desktop) {
        #sticky-block {
          .numbers {
            h2 {
              &.exclusion {
                display: none;
              }
            }
            h4 {
              &.collapsed-box {
                display: inline-block;
                font-size: 1.2rem;
                font-weight: 400;
                padding-right: 1em;
                text-transform: capitalize;
                margin: 0.25em;
              }
            }
            .units {
              font-size: 1.2rem;
            }
            h2 {
              &.collapsed-box {
                display: block;
                padding-right: 1em;
                text-transform: capitalize;
                margin-bottom: 1.3em;
              }
            }
            .acre-cost {
              display: block;
              .CAD-sign {
                font-size: 1.2rem;
              }
              .total-val {
                font-size: rem(42);
                font-weight: 600;
              }
              .cost-units {
                font-size: 1.2rem;
              }
            }

          }
        }
        h2 {
          margin-top: 0;
        }

        .numbers {
          @include material-shadow(3);
          position: fixed;
          top: 0;
          left: 50%;
          margin-left: 11px;
          width: 484px;
          z-index: 2;
          background-color: $light_green;
          padding: 16px;
          overflow: hidden;
          img {
            display: none;
          }
        }

        .value {
          font-size: rem(42);
        }

        .units {
          font-size: rem(24);
        }
      }
    } */

    @media print {
      @include span-columns(6);
    }

    %dynamic {
      color: $grey;
      display: inline-block;
    }

    h2 {
      font-size: rem(22);
      font-weight: 400;
      text-align: center;
    }

    .value {
      @extend %dynamic;
      font-size: rem(116);
      font-weight: 600;
      line-height: 0.85em;

      @include media($desktop) {
        font-size: rem(140);
      }

      @media print {
        color: #000;
      }
    }

    .units {
      @extend %dynamic;
      font-size: rem(18);

      @include media($desktop) {
        font-size: rem(32);
      }

      @media print {
        color: #000;
      }
    }

    .buttons {
      //background-color: $green;
      padding: 0px 24px 24px 24px;

      @media print {
        display: none;
      }
    }
    .result-content {
      margin: 19px 40px;
      background-color: $light_green;
      >#more-info {
        border-top: 1px solid rgba(63, 63, 63, 0.13);
        background-color: unset;
        padding: unset;
        //border-bottom: 1px solid rgba(63, 63, 63, 0.13);
        .read-more {
          display: none;
          &.active {
            display: inline-block;
            p {
              margin-top: 0;
            }
          }

        }
        a {
          color: $darker_green;
          &.toggle {
            text-decoration: underline;
            cursor: pointer;
          }
        }

      }
      >#cost-input {
        align-items: center;
        border-bottom: 1px solid rgba(63, 63, 63, 0.13);
        border-top: 1px solid rgba(63, 63, 63, 0.13);
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-top: 20px;
       /* .text-cost {

          //height: 36px;
          //max-width: 40%;
        }*/
        .cost-field {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
         // max-width: 50%;
          /*input {
            //max-width: 60%;
            //min-width: 50%;
          }*/
          .cost-units {
            width: unset;
            font-size: 1.2rem;
          }
          .CAD-sign {
            width: unset;
            font-size: 1.2rem;
          }
        }
      }
      >#cost-output {
        .text-cost {
          text-align: center;
          //max-width: 279px;
          margin-top: 25px;
        }
        .acre-cost {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 12px;

          .total-val {
            align-self: center;
            color: $medium_grey;
            font-size: 40px;
            font-weight: 600;
            height: 49px;
            line-height: 40px;
            margin: 0 5px;
          }
          .CAD-sign {
            align-self: flex-start;
            font-size: 1.2rem;
            height: 27px;
            color: $medium_grey;
            font-weight: 600;
          }
          .cost-units{
            font-size: 1.2rem;
            height: 27px;
            color: $medium_grey;
            font-weight: 600;
          }
        }
      }
      h2 {
        margin-top: 2em;
      }
    }
  }

  &.harvest-loss .result,
  &.blackleg-loss .result {
		display: block;
		padding: 15px;
  }

  .action-button.submit {
    @include media($desktop) {
      display: none;
    }
  }
}

#sticky-block {
  // @include media($desktop) {
  //   background: $green;
  // }
  .numbers {
    padding: 24px;
    img {
      width: 40px;
      // height: 30px;
    }
  }
}

// Calvin Add
.cap-logo {
  margin: 24px auto 0;
  display: block;
  max-width: 200px;
}

.blackleg-scale {
  margin-bottom: 10px;
  position: relative;
  button {
    background-color: #fff;
  }
  .scale {
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    width: 100%;
  }
}

.zoom-icon {
  width: 100%;
  max-width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 5px;
  margin-right: 5px;
}

// Fix the styling to ensure magnified glass location
.accordion--blackleg-loss {
  .content {
    max-width: 584px;
    margin: 0 auto;
  }
}