#top-nav {
  text-align: center;
  padding: 0;
  background-color: #000;
  color: #fff;
  position: relative;
  height: $nav-height;

  @include media($desktop) {
    background-color: transparent;
    height: auto;
  }

  @media print {
    background-color: transparent;
    color: #000;
  }

  h1 {
    font-size: rem(18);
    font-weight: 400;
    margin: 0;
    line-height: $nav-height;

    @media print {
      font-size: rem(30);
      font-weight: 900;
    }

    @include media($desktop) {
      color: $grey;
      font-size: rem(28);
      line-height: normal;
      margin: 48px 0;
    }
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    background-repeat: no-repeat;

    width: 32px;
    height: 32px;

    @include media($desktop) {
      display: none;
    }

    &.left {
      left: 16px;
      background-position: left center;
      cursor: inherit;
    }

    &.right {
      right: 16px;
      background-image: url("../images/menu.svg");
      background-position: right center;

      &.close {
        background-image: url("../images/close.svg");
      }
    }

    &.back {
      background-image: url("../images/left-arrow.svg");
      cursor: pointer;
    }
  }
}
