$low:  #E4251B;
$high: #3CB963;

$level1: $low;
$level2: lighten($low, 20%);
$level3: $yellow;
$level4: lighten($high, 20%);
$level5: $high;

.rc-slider {
  padding: 8px 0 50px !important;
  margin-bottom: 32px !important;

  @media print {
    margin-bottom: 0 !important;
  }
}

.rc-slider-track {
  background-color: $yellow !important;
  @media print {
    border: 2px solid $yellow !important;
  }

  .level-1 & {
    background-color: $level1 !important;
    @media print {
     border: 2px solid $level1 !important;
    }
  }

  .level-2 & {
    background-color: $level2 !important;
    @media print {
      border: 2px solid $level2 !important;
    }
  }

  .level-4 & {
    background-color: $level4 !important;
    @media print {
      border: 2px solid $level4 !important;
    }
  }

  .level-5 & {
    background-color: $level5 !important;
    @media print {
      border: 2px solid $level5 !important;
    }
  } 
}

.rc-slider-rail {
  @media print {
    background-color: #000 !important;
    height: 40px;

  }
}

.rc-slider-dot {
  background-color: lighten($grey, 30%) !important;
  border-color: lighten($grey, 30%) !important;
  width: 5px !important;
  height: 16px !important;
  border-radius: 4px !important;
  bottom: -6px !important;
}

.rc-slider-dot-active {
  background-color: $yellow !important;
  border-color: $yellow !important;

  .level-1 & {
    background-color: $level1 !important;
    border-color: $level1 !important;
  }

  .level-2 & {
    background-color: $level2 !important;
    border-color: $level2 !important;
  }

  .level-4 & {
    background-color: $level4 !important;
    border-color: $level4 !important;
  }

  .level-5 & {
    background-color: $level5 !important;
    border-color: $level5 !important;
  }
}

.rc-slider-handle {
  @include material-shadow(2);
  background-color: darken($yellow, 10%) !important;
  border-color: darken($yellow, 10%) !important;
  width: 18px !important;
  height: 18px !important;
  margin-left: -9px !important;
  margin-top: -7px !important;

  .level-1 & {
    background-color: darken($level1, 10%) !important;
    border-color: darken($level1, 10%) !important;
  }

  .level-2 & {
    background-color: darken($level2, 10%) !important;
    border-color: darken($level2, 10%) !important;
  }

  .level-4 & {
    background-color: darken($level4, 10%) !important;
    border-color: darken($level4, 10%) !important;
  }

  .level-5 & {
    background-color: darken($level5, 10%) !important;
    border-color: darken($level5, 10%) !important;
  }
}

.rc-slider-mark {
  top: 32px !important;
}

.rc-slider-mark-text {
  color: $grey !important;
}
