@mixin material-shadow($level: 1) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
}

$wrap-width: 1280px;
$green: #ECF7DD;
$light_green: #EBF1DE;
$medium_green: #889A61;
$darker_green: #4F7106;
$nav-height: 82px;
$grey: #3C3C3C;
$medium_grey: #4A4A4A;
$yellow: #FFD204;
$orange: #ffbf44;
$white: #ffffff;
$action-button-height: 50px;
$sidebar-width: 245px;

$desktop: new-breakpoint(min-width 1024px);
$mobile: new-breakpoint(max-width 1024px);
