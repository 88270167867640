.cookie-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: black;
  position: fixed;
  left: 0px;
  bottom: 0;
  width: 100%;
  z-index: 999;

  a {
    color: #FFDD00;
  }

  @media print {
    display: none;
  }
}

.cookie-content {
  width: 100%;
  p {
    font-size: 9px;
    margin: 15px;
  }
  a {
    color: #FFDD00;
  }
}

.cookie-dismiss {
  padding: 12px 25px;
  font-size: 9px;
  font-weight: 700;
  font-family: "proxima-nova", sans-serif;
  text-transform: uppercase;
  background-color: #FFDD00;
  margin: 15px;
}

@media (min-width: 600px) {
  .cookie-content {
    p {
      font-size: 12px;
    }
  }
  .cookie-dismiss {
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  .cookie-content {
    max-width: 800px;
    p {
      font-size: 14px;
    }
  }
  .cookie-dismiss {
    font-size: 14px;
  }
}