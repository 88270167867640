html {
  min-height: 100vh;
  margin: 0;
}

body {
  background-color: #fff;
  color: $grey;
  font-size: 16px;
  @extend %font-proximanova;
  font-weight: 400;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  @extend %font-proximanova;
  font-weight: 700;
}

a {
  color: $darker_green;

  &:hover {
    color: #000;
  }
}

p {
  &.pad {
    padding-top: 0;
    padding-bottom: 0;
  }

  img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
  }
}

small {
  font-size: rem(12);
}

.tabs-intro {
  border: 1px solid $grey;
  border-radius: 10px;
  margin: 0px 40px;
  text-align: center;
}

#page {
  min-height: 100vh;

  @include media($desktop) {
    display: flex;
    flex-direction: column;
  }

  &.sidebar-open {
    #sidebar {
      transform: translateX(0);
    }

    #main {
      transform: translateX($sidebar-width * -1);
    }
  }
}

#main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-height: 100vh; */
  transition: transform 0.5s ease;
  padding-bottom: 0;

  @include media($desktop) {
    display: block;
    min-height: 0;
    transition: transform 0s;
    /* flex: 1 0 auto; */
    padding-bottom: 0;
    z-index: 0;
  }

  &.menu {
    #content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .pad {
      flex: 1 0 auto;
    }
  }

  // this isn't working anymore, the class changed
  &.home {
    padding-bottom: 0;
    #top-nav {
      @include media($desktop) {
        display: none;
      }
    }
  }
  &.calculator {
    padding-bottom: 0;
    #top-nav {
      @include media($desktop) {
        display: none;
      }
    }
  }
}

#content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include media($desktop) {
    display: block;
  }
}

.wrap {
  @include media($desktop) {
    @include outer-container($wrap-width);
  }
}

.pad {
  padding: 16px;
}

.centred {
  text-align: center;

  @media print {
    color: #000;
  }
}

button {
  color: #000;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;

  &:focus {
    outline: none;
  }
}

.accordion {
  list-style-type: none;
  margin: 40px 0 0;
  padding: 3px;

  @include media($desktop) {
    padding: 0;
  }

  li {
    position: relative;
    overflow: hidden;
    margin-bottom: 3px;

    @include media($desktop) {
      background-color: transparent;
      margin-bottom: 32px;
    }

    &.active {
      background-color: transparent;

      .toggle::after {
        background-image: url("../images/up-arrow.svg");
      }

      .content {
        display: block;

        @media print {
          display: none;
        }
      }
    }
  }

  .toggle {
    background-color: $green;
    color: #000;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    padding: 20px 24px 20px 55px;
	position: relative;
	font-size: 0.9em;
	
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	@media (min-width: 480px) {
		font-size: 1em;
	}

	&--no-modal {
		padding-left: 20px;
	}

    &::after {
		// Jesse - 2020-12-08 - this was not displayed; is it even used?
		display: none;

      content: "";
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: right center;
      background-image: url("../images/down-arrow.svg");
      width: 32px;
      height: 32px;
      z-index: 0;
    }
  }

  .content {
    position: relative;
    display: none;
	padding: 20px 24px;
  }

	&--harvest-loss,
  &--blackleg-loss {
		.content {
			padding-bottom: 0;
		}

		li {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			@include media($desktop) {
				margin-bottom: 0;
			}
		}
	}

  .qval {
    color: $medium_green;
    font-size: rem(18);
    font-weight: 700;
    float: right;
    min-width: 32px;
    background-color: $green;
    text-align: right;
    position: relative;
	z-index: 1;
	
	// Align flex item left
	margin-left: auto;
	margin-right: 0;

    @media print {
      color: #000;
    }
  }
}

.action-button {
  display: block;
  width: 100%;
  height: $action-button-height;
  background-color: $yellow;
  border-radius: $action-button-height;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.63px;
  line-height: 35.71px;
  &.email {
    background-color: $white;
    border: 2px solid $yellow;
  }
  &.print {
    background-color: $white;
    border: 2px solid $yellow;
  }

  &:hover {
    @include material-shadow(2);
  }

  &:active {
    @include material-shadow(3);
  }
}
.button-wrapper {
  position: relative;
}
.info {
  background: transparent url("../images/info-icon.svg") no-repeat center center;
  background-size: contain;
  font-size: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 12px;
  top: 14px;
  z-index: 1;
  &.relative {
    position: relative;
    left: -10px;
    top: -5px;
  }

  @media print {
    opacity: 0;
  }

  @media (hover) {
    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  .tooltip {
    @include material-shadow(3);
    background-color: $grey;
    border-radius: 4px;
    color: $yellow;
    display: none;
    font-size: rem(14);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(110%, -50%);
    width: 140px;
    padding: 4px 16px;

    @media print {
      display: none;
    }

    &::before {
      content: "";
      @include triangle(18px, $grey, left);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-100%, -50%);
    }
  }
}

.field-centred {
  text-align: center;

  input {
    display: inline-block;
    font-size: rem(32);
    width: 124px;
    text-align: center;
    background-color: transparent;
    border: none;
    border: 2px solid $yellow;
    border-radius: 5px;
    min-height: 59px;

    &:focus {
      outline: none;
	}

	$placeholder-size: 1.5rem;
	&::-moz-placeholder { font-size: $placeholder-size; }
	&::-webkit-input-placeholder { font-size: $placeholder-size; }
	&::placeholder { font-size: $placeholder-size; }
	
  }
  &.cost-field {
    input {
      background-color: $white;
      border: 2px solid $medium_green;
      font-weight: 600;
    }
    span {
      &.CAD-sign {
        font-weight: 600;
        height: 28px;
        margin-right: 14px;
        width: 28px;
      }

    }
    span {
      &.cost-units {
        font-weight: 600;
        height: 28px;
        margin-left: 14px;
        width: 28px;
      }
    }
  }
}

input {
  &[type=number] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.error {
  color: red;
}

#about,#resources {
  .wrap {
    @include outer-container(600px);
    padding: 0 16px;

    @include media($desktop) {
      @include padding(null 0);
    }
  }
  h3:not(:first-child) {
    margin-top: 2em;
  }
  h3:nth-of-type(2) {
    margin-top: 5em;
  }
}

// https://github.com/twbs/bootstrap/blob/v4.6.1/scss/mixins/_screen-reader.scss
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}