#sidebar {
  background-color: $white;
  width: $sidebar-width;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(#{$sidebar-width + 10});
  transition: transform 0.5s ease;
  @include material-shadow(3);
  z-index: 1;

  @include media($desktop) {
    background-color: $white;
    box-shadow: none;
    width: 100%;
    height: 110px;
    position: static;
    padding: 0;
    transform: translateX(0);
    transition: transform 0s;
  }

  @media print {
    display: none;
  }

  .wrap {
    max-width: 1920px;
    margin-left: 20px;
    margin-right: 20px;

    @include media($desktop) {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
    }
  }

  #logo {
    display: inline-flex;
    width: 100%;
    background: $white;
    padding: 14px;

    @include media($desktop) {
      display: inline-flex;
      width: 267px;
      vertical-align: middle;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include media($desktop) {
      display: inline-flex;
      justify-content: flex-end;
      margin-top: 0;
      padding-left: 40px;
      height: 100%;
      text-align: right;
      width: calc(100% - 192px);
      vertical-align: middle;
    }
  }

  $arrow-size: 6px;
  .arrow-down {
    width: 0;
    height: 0;
    border-left: ($arrow-size * 0.7) solid transparent;
    border-right: ($arrow-size * 0.7) solid transparent;
    border-top: $arrow-size solid black;
    display: inline-block;
    line-height: $arrow-size * 3;
    vertical-align: middle;
  }
  .sub-nav-title {
    display: none;
    @include media($desktop) {
      display: inline-block;
    }
  }
  .sub-nav {
    @include media($desktop) {
      display: none;
      position: absolute;
      top: calc(100% - 25px);
      left: 0;
      margin: 0;
      padding: 10px 0;
      width: 250px;
      text-align: left;
      border: 1px solid $grey;
      background: white;
      height: auto;
      li, li.active {
        margin: 0;

        width: 100%;
        height: auto;
        display: block;

        color: #000;
        font-weight: 900;
        a {
          color: #000;
          border: 0;
          font-weight: 900;
          padding: 5px 20px;
          display: block;
        }
      }
      li:hover {
        background: darken(white, 10%);
      }
    }
  }

  li {
    // padding-left: 32px;
    margin-bottom: 15px;
    font-weight: 900;


    @include media($desktop) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 4%;
      line-height: 110px;
      position: relative;

      &:hover {
        .sub-nav {
          display: block;
        }
      }

    }
    @include media($mobile) {
      margin-left: 10px;
      .sub-nav {
        margin-top: 16px;
        li {
          margin-left: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    // styles for the icons in the nav
    a, span {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 25px;
      color: $grey;
      display: block;
      font-size: rem(16);
      text-decoration: none;
      width: 100%;
      padding-left: 32px;

      @include media($desktop) {
        background-image: none !important;
        border-bottom: 2px solid transparent;
        color: #000;
        display: inline-block;
        font-size: rem(16);
        line-height: 1.4em;
        padding: 4px;
      }

      &.home {
        background-image: url("../images/menu-home1.png");
      }

      &.target-density {
        background-image: url("../images/menu-target-density1.png");
      }

      &.seed-rate {
        background-image: url("../images/menu-seed-rate1.png");
      }

      &.combine-optimization {
      background-image: url("../images/menu-combine-optimization-tool1.png");
      }
      
      &.harvest-loss {
        background-image: url("../images/menu-harvest-loss1.png");
        background-size: 20px;
        background-position: left top;
      }

      &.blackleg-loss {
        background-image: url("../images/menu-blackleg-yield-loss1.png");
        background-size: 20px;
      }

      &.my-fields {
        background-image: url("../images/menu-canola-counts1.png");
      }

      &.resources {
        background-image: url("../images/menu-resources.png");
      }

      &.about {
        background-image: url("../images/menu-about.png");
      }

      &.maximum-yield-diagnostic {
        background-image: url("../images/menu-combine.png");
      }

      &.sclero-risk {
        background-image: url("../images/menu-Sclerotinia-Risk-Tool-Icon-vector.svg");
      }

      &.sclero-calculator {
        background-image: url("../images/menu-Sclerotinia-Launch-Calculator-Icon-yellow.svg");
        background-size: auto 22px;
      }

      &.sclero-resources {
        background-image: url("../images/menu-Sclerotinia-Launch-Resources-Icon.png");
        background-size: auto 21px;
      }

      &.sclero-login {
        background-image: url("../images/menu-Sclerotinia-Launch-Login-Icon.png");
        background-size: auto 22px;
      }

      &:hover {
        @include media($desktop) {
          border-color: #fff;
        }
      }
    }

    &.active {
      a {
        font-weight: bold;

        @include media($desktop) {
          border-color: $yellow;
        }
      }
    }
  }
}

//above 1920px wide screen sizes, the sidebar wrap should be centered
@media (min-width: 1921px) {
  #sidebar .wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

.active-sidebar-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}
.sidebar-open {
  position: fixed;
  .active-sidebar-overlay {
    display: block;
  }
}

.back-to-ccc a {
    background-color: #FFDD00;
    padding: 10px 10px;
    text-transform: uppercase;
    color: black;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
    width: auto;
    text-decoration: none;
    transition: background-color 0.15s ease;

    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;

    @include media($desktop) {
      margin-left: 40px;
      width: 263px;
    }

    @media (max-width: 1024px) {
      position: static;
      margin-top: 10px;
    }
    
    /* @media (min-width: 1024px) {
      right: calc((100% - 1024px) / 2);
    } */
}

.back-to-ccc a:hover {
    background-color: #f7c500;
}

.back-to-ccc img {
    margin-right: 5px;
    height: 1.4em;
    width: auto;
}